import { Helmet } from 'react-helmet-async';
import gql from 'graphql-tag';
import { useMetaTitle } from '../../hooks/useMetaTitle';
import { useMetaDescription } from '../../hooks/useMetaDescription';
import { useTranslation } from '../../hooks/useTranslation';
import { useFrontendConfig } from '../../context/FrontendConfigContext';
import { useGlobalsContext } from '../../context/GlobalsContext';
import { memoizeWith } from '../../utils/memoize';

const fragmentName = ({ type }) => `${type}MetaData`;
export const metaDataFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    ({ type }) => type,
    ({ type }) => gql`
      fragment ${fragmentName({ type })} on ${type} {
        name
        description
        teaser
        lastPublicationDate
        firstPublicationDate
        ${type === 'Navigation' ? 'pageTitle' : ''}
      }
    `,
  ),
};

// This is a hard coded, because it is not supposed to be environment dependent.
const canonicalOrigin = 'https://learngerman.dwturkce.com';

export const PageMetaData = ({
  pageType,
  content = {},
  parentContentName,
  translationFirst = false,
  useTeaserAsDescription = false,
  useDescriptionTranslation = false,
  addDescription = true,
  relativeCanonicalUrl,
  noSearchEngine = false,
}) => {
  const {
    name: contentName,
    description,
    teaser,
    lastPublicationDate,
    firstPublicationDate,
    pageTitle,
  } = content;
  const siteName = useTranslation('metadata.siteName');
  const { staticBaseHost } = useFrontendConfig();
  const title = useMetaTitle({ pageType, translationFirst, contentName, parentContentName });
  const descriptionMeta = useMetaDescription({
    pageType,
    contentName,
    teaser,
    description,
    useTeaserAsDescription,
    useDescriptionTranslation,
  });
  const contentFullUrl = useGlobalsContext().window.location.href;
  const showDescription = addDescription && descriptionMeta;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="preconnect" href={staticBaseHost} />
      <link rel="dns-prefetch" href={staticBaseHost} />
      {relativeCanonicalUrl && (
        <link rel="canonical" href={`${canonicalOrigin}${relativeCanonicalUrl}`} />
      )}
      <meta
        name="copyright"
        content={`© ${new Date().getFullYear()} Deutsche Welle (www.dw.com)`}
      />
      {showDescription && <meta name="description" content={descriptionMeta} />}
      {showDescription && <meta property="og:description" content={descriptionMeta} />}
      <meta property="og:title" content={pageTitle || title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={contentFullUrl} />
      <meta name="last-modified" content={lastPublicationDate} />
      <meta name="date" content={firstPublicationDate} />
      {noSearchEngine && <meta name="robots" content="none" />}
    </Helmet>
  );
};
